/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://d626fr5flrf6lc5tyjkzczge2a.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-w56q5blr7vgd7dgs7gqpx6yoii",
    "aws_cloud_logic_custom": [
        {
            "name": "lghbCustomer",
            "endpoint": "https://wqe8lszxme.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-2:b5690fcd-b5c0-407c-9266-995ad9614aa7",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_v7ZrZ5YlD",
    "aws_user_pools_web_client_id": "5blsih3qjd74kimen19lgi70n",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lghbflagshipstorage151622-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
